$(document).on('click', '.organisation_filter', function() {
  $.ajax({
    type: "GET",
    url: '/organisations',
    data: {filter: $("input[name='filter']:checked").val() },
    dataType: "script"
  });
});

$(document).on('click', '#delete_logo_link', function() {
  $('#organisation_delete_logo').val("true");
  $('#organisation_logo').remove();
  $('#delete_logo_link').hide();
});
