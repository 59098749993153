$(document).ready(function () {
  var activeTab = localStorage.getItem('activeTab');
  if(activeTab){
    $('.nav-tabs a[href="' + activeTab + '"]').tab('show');
  }


  if ( $("div#document-dropzone").length != 0 ) {
    if ( !$("div#document-dropzone").hasClass('dz-clickable') ) {
      new Dropzone("div#document-dropzone", {
        url: "/engagements/" + $('#engagement_id').val() + "/add_document",
        paramName: 'document',
        headers: {
          'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
        },
        maxFilesize: 5, //MB
        acceptedFiles:  "application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation,application/octet-stream,application/pdf,application/vnd.ms-outlook,application/zip,image/jpeg,image/jpg,image/png,text/html,text/plain,text/rtf"
      });
    }
  }
  
});


$(document).on('ajax:send', 'a', function(){
 return passCheckDirty();
});


$(document).on('trix-blur', function(){
 if ( !$('form.edit_engagement').hasClass('dirty') ) { $('form.edit_engagement').addClass('dirty') }
});


function passCheckDirty(){
  if ($('form.edit_engagement').hasClass('dirty') ) {
    var r = confirm('Leave page?  Changes that you made may not be saved.'); 
    $('form.edit_engagement').trigger('rescan.areYouSure');
    if (r == false ) {
      return false;
    } else {
      $('form.edit_engagement').removeClass('dirty').trigger('reinitialize.areYouSure');
      return true;
    }
  } else {
    return true;
  }
}

$(document).on('click', '.engagement_filter', function() {
  $.ajax({
    type: "GET",
    url: '/engagements',
    data: {filter: $("input[name='filter']:checked").val() },
    dataType: "script"
  });
});

$(document).on('click', 'button.next-step', function(){
  tab = $('.nav-tabs .active').parent().next('li').find('a').attr('href');
  $('.nav-tabs a[href="' + tab + '"]').tab('show');
  $('input[autofocus="autofocus"]').focus();
});

$(document).on('click', 'button.prev-step', function(){
  tab = $('.nav-tabs .active').parent().prev('li').find('a').attr('href');
  $('.nav-tabs a[href="' + tab + '"]').tab('show');
  $('input[autofocus="autofocus"]').focus();
});

$(document).on('show.bs.tab', 'a[data-bs-toggle="tab"]', function(e) {
  if ( passCheckDirty() == true ) {
    localStorage.setItem('activeTab', $(e.target).attr('href'));
    $('input[autofocus="autofocus"]').focus();
  } else {
    return false;
  }
});

$(document).on('click', '#engagement input[type=submit]', function(e) {
  var tab = '';
  if( $(this).hasClass('prev-step') ) {
    tab = $('.nav-tabs .active').parent().prev('li').find('a').attr('href');
  } else if ($(this).hasClass('next-step') ) {
    tab = $('.nav-tabs .active').parent().next('li').find('a').attr('href');
  } else {
    tab = $('.nav-tabs .active').parent().find('a').attr('href');
  }
  localStorage.setItem('activeTab', tab );
});


$(document).on('change', '#engagement_theme', function(){
  if ($(this).val() == 'Other') {
    $('#engagement_theme_other').parent().show();
    $('#engagement_theme_other').focus();
  } else {
    $('#engagement_theme_other').parent().hide();
    $('#engagement_theme_other').val('')
  }
});

$(document).on('click', '.engagement_report_link', function(){
  if ($(this).closest('.tab-pane, section').find('#hide_unanswered:checked').length ) {
    if ( $(this).attr('href').indexOf("?hide_unanswered=true") == -1 ){
      $(this).attr('href', $(this).attr('href') + "?hide_unanswered=true");
    }
  } else {
    $(this).attr('href', $(this).attr('href').replace("?hide_unanswered=true", "") );
  }
  if ( $("input[type=radio][name='report_selection']").length ) {
    $(this).attr('href', $(this).attr('href').replace(/\w+.pdf/, $("input[type=radio][name='report_selection']:checked").val() + '.pdf' ) );
  }
});
