$(document).on('click', '.user_filter', function() {
  $.ajax({
    type: "GET",
    url: '/user_accounts',
    data: {filter: $("input[name='filter']:checked").val() },
    dataType: "script"
  });
});

$(document).on('click',  '#terms_link', function(){
  $('#terms-text').toggle();
  return false;
});

$(document).on('click',  '.register_as_selector', function(){
  if ( $("input[name='user[register_as]']:checked").val() == 'Individual' ) {
    $('#register_as_organisation').hide();
  } else {
    $('#register_as_organisation').show();
  }
  show_subscription_message();
  
});




$(document).on('change', '#user_region', function() {
  show_subscription_message();
});

function show_subscription_message(){
  if ( $('#user_region').val() == 'scotland' && $("input[name='user[register_as]']:checked").val() == 'Individual' )  {
    $('#subscription_account_msg').hide();
    $('#free_account_msg').show();
  } else {
    $('#subscription_account_msg').show();
    $('#free_account_msg').hide();
  }
}


