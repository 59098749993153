

$(document).ready(function() {

  setup_tooltips();
  
  // register date format with moment for datatable sorting
  // $.fn.dataTable.moment( 'DD/MM/YYYY' );
  // $.fn.dataTable.moment( 'DD/MM/YYYY HH:mm' );
  
  draw_dataTable('#admin_lookup_datatable', true);

  $("input[type='number']").inputSpinner();

  setup_popovers();
  
  $('input[autofocus="autofocus"]').focus();
  
  $('form.areyousure').areYouSure();

});

// remove popovers when clicking anywhere else
$('html').on('click', function (e) {
    $('[data-bs-toggle="popover"],[data-original-title]').each(function () {
        //the 'is' for buttons that trigger popups
        //the 'has' for icons within a button that triggers a popup
        if (!$(this).is(e.target) && $(this).has(e.target).length === 0 && $('.popover').has(e.target).length === 0) {
          $(this).popover("hide");
        }
    });
});

window.setup_popovers = function(){
  $("a[data-bs-toggle='popover']").popover({
    container: 'body',
    html: true,
    content: function(){
      var clone = $($(this).parent().find('.help-text-content')).clone(true).show();
      return clone;
    },
    title: function(){
      return $(this).data('title');
    }
  }).click(function(e){
    e.preventDefault(); 
  });  
}
  
window.setup_tooltips = function(){
  var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
  var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
    return new bootstrap.Tooltip(tooltipTriggerEl, { 
      animation: false 
    })
  });
}


window.draw_dataTable =  function(dTable, with_search) {
  with_search = typeof with_search !== 'undefined' ? with_search : true;
  if (with_search) {
    oTable = $(dTable).DataTable({   // returns DataTables instance rather than dataTable which is jquery object
      paging: false,
      filter: with_search,
      autoWidth: true,
      stateSave: true, 
      columnDefs: [   { targets: 'no-sort', orderable: false },
                      { targets: 'checkbox-sort', orderDataType: "dom-checkbox", type: 'numeric' },
                      { targets: 'input-char-sort', orderDataType: "dom-text" },
                      { targets: 'input-numeric-sort', orderDataType: "dom-text", type: 'numeric' }
                   ] ,
      dom: 'Bfrtip',      // https://datatables.net/reference/option/dom             
      buttons: [
              {
                  text: '<i title= "Clear search" class="fas fa-fw icon-gradient fa-remove"></i>',
                  action: function ( e, dt, node, config ) {
                    dt.search('').draw();
                  }
              }]
    });
  } else {
    oTable = $(dTable).DataTable({   // returns DataTables instance rather than dataTable which is jquery object
      paging: false,
      filter: with_search,
      autoWidth: true,
      stateSave: true, 
      columnDefs: [   { targets: 'no-sort', orderable: false },
                      { targets: 'checkbox-sort', orderDataType: "dom-checkbox", type: 'numeric' },
                      { targets: 'input-char-sort', orderDataType: "dom-text" },
                      { targets: 'input-numeric-sort', orderDataType: "dom-text", type: 'numeric' }
                   ]  
    });
  
  }
  return oTable;
}

// Ensure dataTable sorts on checked state of checkboxes & checkbox icons. or use using data { sort: ''}} on td instead
$.fn.dataTable.ext.order['dom-checkbox'] = function  ( settings, col )
  {
    return this.api().column( col, {order:'index'} ).nodes().map( function ( td, i ) {
        return  ( $('input', td).prop('checked') ? '1' : '0' );
    } );
};
// $.fn.dataTable.ext.order['dom-text'] = function  ( settings, col )
// {
//     return this.api().column( col, {order:'index'} ).nodes().map( function ( td, i ) {
//         return $('input', td).val();
//     } );
// };

$(document).on('click', '.reset-tab', function(){
  var activeTab = localStorage.setItem('activeTab', '');
});


$(document).on('click', '#select_all', function(){
  $('td input:checkbox').prop( "checked", $(this).is(':checked') );
});

  

$(document).on('click', '.item_delete', function(){
  fnDeleteItemConfirmation($(this).data('item-type'), $(this).data('item-id'), $(this).data('dialog-title'), $(this).data('dialog-prompt') );
  return false;
});


window.fnDeleteItemConfirmation = function(item_type, item_id, title, prompt) {
  
  if ( title  !=  undefined ) {  $('#delete-dialog .modal-title').html(title) }
  if ( prompt != undefined ) {  $('#delete-dialog .modal-body .container-fluid p').html(prompt) }
  $('#delete-dialog').modal('show');
  
  // Disable previous binds & re-associate
  $('#button-yes').off("click");
  $('#button-yes').click(function () {
    fnDelItemConfirmed(item_type, item_id);
    $('#delete-dialog').modal('hide');
    if ( title  !=  undefined ) {  $('#delete-dialog .modal-title').html("Delete Confirmation") }
    if ( prompt != undefined ) {  $('#delete-dialog .modal-body .container-fluid p').html("Are you sure you want to delete this item?") }
  }); 
  $('.button-no').click(function() {
    $('#delete-dialog').modal('hide');
    if ( title  !=  undefined ) {  $('#delete-dialog .modal-title').html("Delete Confirmation") }
    if ( prompt != undefined ) {  $('#delete-dialog .modal-body .container-fluid p').html("Are you sure you want to delete this item?") }
  });
  
  return false;
}

window.fnDelItemConfirmed = function(item_type, item_id) {
  var jqxhr = $.ajax({url:'/' + item_type + 's/' + item_id, type: 'delete', dataType: 'script'});
  jqxhr.done(function(data) { 
    toastr.success("Item deleted successfully", "VOiCE success", {closeButton: true, timeout: 10000, extendedTimeOut: 20000});
    $("tr[data-id='" + item_id + "'").remove();
  });

  jqxhr.fail(function(xhr, ajaxOptions, thrownError) { 

    // xhr is a XMLHttpRequest object.
    toastr.error("An exception was thrown deleting the row : " + xhr.responseText, "VOiCE error",  {closeButton: true, timeOut: 10000, extendedTimeOut: 20000});    
  });
  return false;
}


  
